<template>
    <div>
        <v-card flat>
        <v-card-text>
        <!-- alterei o ref -->
        <v-form @submit.prevent="createDoadorReports" ref="form">
            <v-container>
            <v-row>
                <v-col class="d-flex" cols="12" sm="2">
                    <v-text-field 
                    color="#086318" 
                    :rules="formRules" 
                    label="Data de Admissão"
                    type="date" 
                    outlined 
                    v-model="reportsForm.admission_date"
                    dense></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="1">
                    <v-text-field 
                    color="#086318" 
                    label="Altura"
                    v-mask="'#.##'"
                    maxlength="4" 
                    outlined
                    dense
                    v-model="reportsForm.height">
                    </v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="1">
                    <v-text-field 
                    color="#086318" 
                    label="Peso"
                    v-mask="'##.##'"
                    maxlength="7" 
                    outlined
                    dense
                    v-model="reportsForm.weight">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="d-flex" cols="12" sm="2">
                    <v-select 
                    color="#086318" 
                    v-model="reportsForm.diagnostic_id" 
                    :items="diagnostics"
                    item-text="name" 
                    item-value="id" 
                    dense 
                    outlined
                    label="Suspeita de Diagnóstico">
                    </v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="3">
                    <v-select 
                    color="#086318" 
                    v-model="reportsForm.comorbidity_id" 
                    :items="comorbidities"
                    item-text="name" 
                    item-value="id" 
                    dense 
                    outlined
                    label="Comorbidades">
                    </v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="4">
                    <v-text-field 
                    color="#086318" 
                    label="Causa Provável da Morte"
                    outlined
                    dense
                    v-model="reportsForm.probable_cause">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="d-flex" cols="12" sm="2">
                    <v-radio-group :rules="formRules" v-model="reportsForm.donor_diabetes">
                        <p>Diabetes no doador?</p>

                        <v-radio label="Sim" 
                        value="1" 
                        color="#086318"
                        ></v-radio>

                        <v-radio label="Não" 
                        value="2" 
                        color="#086318"
                        ></v-radio>
                    </v-radio-group>
                </v-col>

                <v-col class="d-flex" cols="12" sm="3">
                    <v-radio-group :rules="formRules" v-model="reportsForm.diabetes_relatives">

                    <p>Diabetes em parentes de 1ª grau?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>

                <v-col class="d-flex" cols="12" sm="2">
                    <v-radio-group :rules="formRules" v-model="reportsForm.bronchoaspiration">
                    <p>Broncoaspiração no Internamento?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>


                <v-col class="d-flex" cols="12" sm="2">
                    <v-radio-group :rules="formRules" v-model="reportsForm.inpatient_dialysis">

                    <p>Diálise no Internamento?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="d-flex" cols="12" sm="1">
                    <v-radio-group :rules="formRules" v-model="reportsForm.dst">
                    <p>DST?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>

                <v-col class="d-flex" cols="12" sm="1">
                    <v-radio-group :rules="formRules" v-model="reportsForm.smoker">
                    <p>Tabagista?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col class="d-flex" cols="12" sm="2" :rules="formRules" v-if="reportsForm.smoker == 1">
                    <v-text-field 
                    color="#086318" 
                    label="Há quanto tempo?" 
                    dense
                    v-model="reportsForm.how_long" 
                    ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="1">
                    <v-radio-group :rules="formRules" v-model="reportsForm.previous_surgery">
                    <p>Cirúrgia Prévia?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col class="d-flex" cols="12" sm="2" :rules="formRules" v-if="reportsForm.previous_surgery == 1">
                    <v-text-field 
                    color="#086318" 
                    label="Qual?" 
                    dense
                    v-model="reportsForm.which_surgery" 
                    ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="1">
                    <v-radio-group :rules="formRules" v-model="reportsForm.heart_disease">

                    <p>Cardiopatia?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col class="d-flex" cols="12" sm="2" :rules="formRules" v-if="reportsForm.heart_disease == 1">
                    <v-text-field 
                    color="#086318" 
                    label="Qual?" 
                    dense
                    v-model="reportsForm.which_heart_disease" 
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="d-flex" cols="12" sm="2">
                    <v-radio-group :rules="formRules" v-model="reportsForm.has">
                    <p>HAS?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>

                <v-col class="d-flex" cols="12" sm="2">
                    <v-radio-group :rules="formRules" v-model="reportsForm.asthma">
                    <p>Asma?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>
                
                <v-col class="d-flex" cols="12" sm="2">
                    <v-radio-group :rules="formRules" v-model="reportsForm.dpoc">
                    <p>DPOC?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>

                <v-col class="d-flex" cols="12" sm="2">
                    <v-radio-group :rules="formRules" v-model="reportsForm.infectious_disease">
                    <p>Doença Infectocontagiosa?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col class="d-flex" cols="12" sm="2" :rules="formRules" v-if="reportsForm.infectious_disease == 1">
                    <v-text-field 
                    color="#086318" 
                    label="Qual?" 
                    dense
                    v-model="reportsForm.which_infectious" 
                    ></v-text-field>
                </v-col>
            </v-row>
            <br>
          <v-row>
            <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.facial_trauma">
                  <p>Trauma na face?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.corneal_surgery">
                  <p>Relato de cirurgia na córnea?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="1">
                  <v-radio-group :rules="formRules" v-model="reportsForm.tattoo">
                  <p>Tatuagens?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="1" v-if="reportsForm.tattoo == 1">
                  <v-text-field 
                  color="#086318" 
                  label="Tempo:" 
                  dense
                  v-model="reportsForm.tattoo_time" 
                  ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.illicit_drugs">
                  <p>Usuários de drogas ilícitas?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>

              <v-col class="d-flex" cols="12" sm="1">
                  <v-radio-group :rules="formRules" v-model="injecting" v-if="reportsForm.illicit_drugs == 1">
                  <p>Injetáveis?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-model="reportsForm.injecting_drugs"  v-if="injecting == 1">
                <v-textarea 
                  color="#086318" 
                  label="Quais?"
                  row-height="12"
                  rows="4"
                  outlined
                  dense
                  v-model="reportsForm.injecting_drugs">
                  </v-textarea>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="injecting == 2">
                <v-textarea 
                  color="#086318" 
                  label="Quais não injetáveis?"
                  row-height="12"
                  rows="4"
                  outlined
                  dense
                  v-model="reportsForm.non_injecting_drugs">
                  </v-textarea>
              </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.piercing">
                  <p>Piercings?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.alcoholic">
                  <p>Alcoolista?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col><v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.alcoholic == 1">
                  <v-text-field 
                  color="#086318" 
                  label="Tipo:" 
                  dense
                  v-model="reportsForm.type_of_alcoholic" 
                  ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.alcoholic == 1">
                  <v-text-field 
                  color="#086318" 
                  label="Tempo:" 
                  dense
                  v-model="reportsForm.time_of_alcoholic" 
                  ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.alcoholic == 1">
                  <v-text-field 
                  color="#086318" 
                  label="Frequência:" 
                  dense
                  v-model="reportsForm.frequency_of_alcoholic" 
                  ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="5">
                <v-textarea 
                  color="#086318" 
                  label="Outros"
                  row-height="15"
                  rows="6"
                  outlined
                  dense
                  v-model="reportsForm.others">
                  </v-textarea>
              </v-col>
              <v-col class="d-flex" cols="12" sm="1">
                <v-tooltip top color="red">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-1" small fab dark color="red" v-bind="attrs" v-on="on" @click="clearOther()">
                            <v-icon dark>mdi-broom</v-icon>
                        </v-btn>
                    </template>
                    <span style="color:white">Limpar</span>
                </v-tooltip>
                <v-tooltip top color="green">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-1" small fab dark color="green" v-bind="attrs" v-on="on" @click="createDoadorReports()">
                            <v-icon dark>mdi-check-bold</v-icon>
                        </v-btn>
                    </template>
                    <span style="color:white">Salvar</span>
                </v-tooltip>
            </v-col>
          </v-row>
        
          <p class="space-personalized"></p>

            <div class="buttons">
                <v-row>
                  <v-btn class="rounded-lg" type="submit" elevation="0" color="green">
                      <span class="textBtn"> Atualizar Informes </span>
                  </v-btn>
                </v-row>
            </div>


          <br><br>
          <h3>Antecedentes:</h3>
          <v-row>
            <v-col class="d-flex" cols="12" sm="3">
                  <v-radio-group :rules="formRules" v-model="reportsForm.blood_receiver">
                  <p>Recebeu sangue e Hemoderivados?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                <v-text-field 
                  color="#086318" 
                  label="Tipo:"
                  outlined
                  dense
                  v-model="bloodProductsForm.type_of_blood_receiver">
                  </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                <v-text-field 
                  color="#086318" 
                  label="Volume:"
                  outlined
                  dense
                  v-model="bloodProductsForm.volume_of_blood_receiver">
                  </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                <v-text-field 
                  color="#086318" 
                  label="Data:"
                  type="date" 
                  outlined 
                  v-model="bloodProductsForm.data_of_blood_receiver"
                  dense></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="1">
                <v-tooltip top color="green">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-1" small fab dark color="green" v-bind="attrs" v-on="on" @click="addHemoderivados()">
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span style="color:white">Inserir</span>
                </v-tooltip>
              </v-col>
          </v-row>
          <template>
            <v-data-table
                :headers="hemoderivadosHeaders"
                :items="hemoderivadosItems"
                :items-per-page="5"
                class="elevation-1">
                <template v-slot:item.actions="{ item }">
                    <v-icon 
                    size="20"
                    class="icon"
                    color="red" 
                    align-item="center"
                    @click="deleteHemoderivados(item)">
                    mdi-trash-can-outline
                    </v-icon>
                </template>
            </v-data-table>
        </template>
        <br>
          <v-row>
            <v-col class="d-flex" cols="12" sm="3">
                  <v-radio-group :rules="formRules" v-model="reportsForm.positive_blood_culture">
                  <p>Hemocultura Positiva?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                <v-text-field 
                  color="#086318" 
                  label="Germe Isolado:"
                  outlined
                  dense
                  v-model="positiveBloodForm.isolated_germ">
                  </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="1">
                <v-tooltip top color="green">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-1" small fab dark color="green" v-bind="attrs" v-on="on" @click="addHemocultura()">
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span style="color:white">Inserir</span>
                </v-tooltip>
              </v-col>
          </v-row>
          <template>
            <v-data-table
                :headers="hemoculturaHeaders"
                :items="hemoculturaItems"
                :items-per-page="5"
                class="elevation-1">
                <template v-slot:item.actions="{ item }">
                    <v-icon 
                    size="20"
                    class="icon"
                    color="red" 
                    align-item="center"
                    @click="deleteHemocultura(item)">
                    mdi-trash-can-outline
                    </v-icon>
                </template>
            </v-data-table>
        </template>
        <br>
          <v-row>
            <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.infection">
                  <p>Infecção?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                <v-text-field 
                  color="#086318" 
                  label="Local:"
                  outlined
                  dense
                  v-model="infectionForm.site_of_infection">
                  </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                <v-text-field 
                  color="#086318" 
                  label="Antibiótico:"
                  outlined
                  dense
                  v-model="infectionForm.infection_antibiotic">
                  </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                <v-text-field 
                  color="#086318" 
                  label="Dose:"
                  outlined
                  dense
                  v-model="infectionForm.dose_of_antibiotic">
                  </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                <v-text-field 
                  color="#086318" 
                  label="Início:"
                  type="date" 
                  outlined
                  dense
                  v-model="infectionForm.antibiotic_start_date">
                  </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="1">
                <v-tooltip top color="green">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-1" small fab dark color="green" v-bind="attrs" v-on="on" @click="addInfections()">
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span style="color:white">Inserir</span>
                </v-tooltip>
              </v-col>
          </v-row>
          <template>
            <v-data-table
                :headers="infectionsHeaders"
                :items="infectionsItems"
                :items-per-page="5"
                class="elevation-1">
                <template v-slot:item.actions="{ item }">
                    <v-icon 
                    size="20"
                    class="icon"
                    color="red" 
                    align-item="center"
                    @click="deleteInfections(item)">
                    mdi-trash-can-outline
                    </v-icon>
                </template>
            </v-data-table>
        </template>
        <br>
          <v-row>
            <v-col class="d-flex" cols="12" sm="3">
                  <v-radio-group :rules="formRules" v-model="reportsForm.material_for_serology">
                  <p>Coleta de material para sorologia?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                <v-text-field 
                  color="#086318" 
                  label="Local da coleta:"
                  outlined
                  dense
                  v-model="materialForSerologyForm.collection_location">
                  </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="1">
                <v-tooltip top color="green">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-1" small fab dark color="green" v-bind="attrs" v-on="on" @click="addSerology()">
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span style="color:white">Inserir</span>
                </v-tooltip>
              </v-col>
          </v-row>

          <template> 
            <v-data-table
              :headers="serologyHeaders"
              :items="serologyItems"
              :items-per-page="10"
              class="elevation-1"
            >
            <template v-slot:item.actions="{ item }">
              <v-icon 
              size="20"
              class="icon"
              color="red" 
              align-item="center"
              @click="deleteSerology(item)">
              mdi-trash-can-outline
              </v-icon>
            </template>
            </v-data-table>
          </template>

            <p class="space-personalized"></p>

            <div class="buttons">
                <v-row>
                  <v-btn class="rounded-lg" type="submit" elevation="0" color="green">
                      <span class="textBtn"> Atualizar Informes </span>
                  </v-btn>
                </v-row>
            </div>
            </v-container>
        </v-form>
        </v-card-text>

        <v-snackbar absolute background-color="white" color="#104a21" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                Fechar
              </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</div>
</template>
<script>

import axios from "@/axios";
import FormatDate from "@/utils/FormatDate"
// import { isArgumentsObject } from "util/types";

export default {
  data() {
    return {
      hemoderivadosHeaders: [
          {
            text: "Tipo",
            align: "center",
            value: "type_of_blood_products",
          },

          { 
              text: "Volume", 
              value: "volume_of_blood_products", 
              align: "center",
              sortable: false 
          },

          { 
              text: "Data", 
              value: "data_of_blood_products", 
              align: "center",
              sortable: false 
          },
          {
            text: "Apagar",
            value: "actions",
          },
      ],
      hemoculturaHeaders: [
        {
          text: "Germe Isolado",
          align: "center",
          value: "isolated_germ",
        },
          {
            text: "Apagar",
            value: "actions",
          },
      ],
      infectionsHeaders: [
        {
          text: "Local",
          align: "center",
          value: "site_of_infection",
        },

        {
          text: "Antibiótico",
          align: "center",
          value: "infection_antibiotic",
        },

        {
          text: "Dose",
          align: "center",
          value: "dose_of_antibiotic",
        },

        {
          text: "Início",
          align: "center",
          value: "antibiotic_start_date",
        },
        {
          text: "Apagar",
          value: "actions",
        },
      ],
      serologyHeaders: [
        {
          text: "Local de Coleta",
          align: "center",
          value: "collection_location",
        },
        {
          text: "Apagar",
          value: "actions",
        },
      ],
      page: 1,
      totalUsers: 0,
      numberOfPages: 0,
      passengers: [],
      loading: true,
      options: {},
      sortBy: "name",
      message: "",
      injecting:"",
      snackbar: false,
      loadingBTN: false,
      hospitalSelected: "",
      exibirMensagem: false,
      messageError: "",
      reportsForm: {
        donor_id: "",
        clinical_reports_id: ""
      },
      diagnostics: [],
      comorbidities: [],
      vue: {
        hasDST: null,
        isSmoker: null,
        hasSugery: null,
        hasInfectiousDiesease: null
      },
      donorID: "",
      donorBack: [],
      donorInfection: [],
      hemoderivadosItems:[],
      hemoculturaItems:[],
      infectionsItems:[],
      serologyItems:[],
      donorBackID: "",
      editForm: {
        
      },
      infectionForm:{
        donor_id: ""
      },
      bloodProductsForm:{
        clinical_reports_id: ""
      },
      positiveBloodForm:{
        clinical_reports_id:""
      },
      materialForSerologyForm:{
        clinical_reports_id:""
      },
      formRules: [
        (v) => !!v || "Este campo é obrigatório",
      ],
    };
  },
  
  created() {
    this.loadSelects()
    this.donorID = this.$route.query.item.id
    axios.get("/clinical_reports").then((response) => {
        this.donorBack = response.data.data;
        this.reportsForm = this.donorBack.filter((x) => x.donor_id == this.donorID)[0];
        this.reportsForm.clinical_reports_id = this.reportsForm.id
        this.reportsForm.comorbidity_id = parseInt(this.reportsForm.comorbidity_id)
        this.reportsForm.diagnostic_id = parseInt(this.reportsForm.diagnostic_id)
        this.reportsForm.blood_receiver = '1'
        this.reportsForm.positive_blood_culture = '1'
        this.reportsForm.infection = '1'
        this.reportsForm.material_for_serology = '1'
        this.reportsForm.injecting_drugs != null ? this.injecting = '1' : this.injecting = '2'
        this.reportsForm.admission_date = FormatDate.dataAtualFormatada(this.reportsForm.admission_date)
        this.getTables()
    })
  },

  methods: {

    indoDataTable() {
      this.loading = true;
    },

    loadSelects(){
      axios.get("/diagnostics").then((response) => {
        this.diagnostics = response.data.data;
      });

      axios.get("/comorbidities").then((response) => {
        this.comorbidities = response.data.data;
      });
    },

    getTables(){
      //Hemoderivados
      axios.get(`/blood_products/${this.reportsForm.clinical_reports_id}`).then((response) => {
        this.hemoderivadosItems = response.data;
      });

      //Hmeocultura Positiva
      axios.get(`/positive_blood_culture/${this.reportsForm.clinical_reports_id}`).then((response) => {
        this.hemoculturaItems = response.data;
      });

      //Infecção
      axios.get(`/infections/byDonor/${this.donorID}`).then((response) => {
        this.infectionsItems = response.data;
      });
      
      //Coleta de material para sorologia
      axios.get(`/material_for_serology/${this.reportsForm.clinical_reports_id}`).then((response) => {
        this.serologyItems = response.data;
      });
    },

    createDoadorReports(){
      if(this.$refs.form.validate()) {
        if(this.reportsForm.probable_cause == null){this.reportsForm.probable_cause = "null"}
        if(this.reportsForm.which_heart_disease == null){this.reportsForm.which_heart_disease = "null"}
        if(this.reportsForm.which_infectious == null){this.reportsForm.which_infectious = "null"}
        if(this.reportsForm.which_surgery == null){this.reportsForm.which_surgery = "null"}
        const newReports = (this.reportsForm)
        axios
          .put(`/clinical_reports/${newReports.id}`, newReports)
          .then((response) => {
            this.indoDataTable();
            this.message = response.data.message;
            this.snackbar = true;
          })
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });

            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
              this.displayError.display = false;
            }, 3000);
          });
      }
    },
    
    //Deletes
    deleteHemoderivados(item){
      axios.delete(`/blood_products/${item.id}`).then((response) => {
          this.loading = true;
          this.message = response.data.message;
          this.snackbar = true;
          this.getTables();
      })
    },
    deleteHemocultura(item){
      axios.delete(`/positive_blood_culture/${item.id}`).then((response) => {
          this.loading = true;
          this.message = response.data.message;
          this.snackbar = true;
          this.getTables();
      })
    },
    deleteInfections(item){
      axios.delete(`/infections/${item.id}`).then((response) => {
          this.loading = true;
          this.message = response.data.message;
          this.snackbar = true;
          this.getTables();
      })
    },
    deleteSerology(item){
      axios.delete(`/material_for_serology/${item.id}`).then((response) => {
          this.loading = true;
          this.message = response.data.message;
          this.snackbar = true;
          this.getTables();
      })
    },

    //Inserts
    addHemoderivados(){
      this.bloodProductsForm.clinical_reports_id = this.reportsForm.clinical_reports_id
      const bloodProducts = this.bloodProductsForm
      axios
        .post(`/blood_products`, bloodProducts).then((response) => {
          this.message = response.message;
          this.snackbar = true;
          this.dialog = false;
          this.message = "Inclua mais hemoderivados clicando no +."
          this.bloodProductsForm.type_of_blood_products = "";
          this.bloodProductsForm.volume_of_blood_products = "";
          this.bloodProductsForm.data_of_blood_products = "";
          this.getTables()
        })
            .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
                error = value;
            });
            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
                this.displayError.display = false;
            }, 3000);
          });
    },

    addHemocultura(){
      this.positiveBloodForm.clinical_reports_id = this.reportsForm.clinical_reports_id
      const positiveCulture = this.positiveBloodForm
      axios
        .post(`/positive_blood_culture`, positiveCulture).then((response) => {
          this.message = response.message;
          this.snackbar = true;
          this.dialog = false;
          this.message = "Inclua mais germes isolados clicando no +."
          this.positiveBloodForm.isolated_germ = "";
          this.getTables()
        })
            .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
                error = value;
            });
            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
                this.displayError.display = false;
            }, 3000);
          });
    },

    addInfections(){
      this.infectionForm.donor_id = this.donorID
      const infectionData = this.infectionForm
      axios
        .post(`/infections`, infectionData).then((response) => {
          this.message = response.message;
          this.snackbar = true;
          this.dialog = false;
          this.message = "Inclua mais antibióticos clicando no +."
          this.infectionForm.antibiotic_start_date = "";
          this.infectionForm.site_of_infection = "";
          this.infectionForm.dose_of_antibiotic = "";
          this.infectionForm.infection_antibiotic = "";
          this.getTables()
          })
            .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
                error = value;
            });
            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
                this.displayError.display = false;
            }, 3000);
          });
    },
    
    addSerology(){
      this.materialForSerologyForm.clinical_reports_id = this.reportsForm.clinical_reports_id
      const materialForSerology = this.materialForSerologyForm
      axios
        .post(`/material_for_serology`, materialForSerology).then((response) => {
          this.message = response.message;
          this.snackbar = true;
          this.dialog = false;
          this.message = "Inclua mais locais de coleta clicando no +."
          this.materialForSerologyForm.collection_location = "";
          this.getTables()
        })
            .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
                error = value;
            });
            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
                this.displayError.display = false;
            }, 3000);
          });
    },

    mounted() {
      this.indoDataTable();
    },
  }

};
</script>

<style scoped>
.header-table {
  display: flex;
  margin-bottom: 20px;
}

.space {
  width: 15px;
}

.spacediferent {
  width: 7px;
}

.space-personalized {
  padding: 15px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right,
      #27ae60,
      #1abc9c);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,
      #ee106d,
      #e46987);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner>h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>
