<template>
  <div>
    <v-card>
      <v-tabs fixed-tabs background-color="green" dark>
        <v-tabs-slider color="#086318"></v-tabs-slider>
        <v-tab>DOADOR</v-tab>
        <v-tab>INFORMES CLÍNICOS</v-tab>
        <!-- <v-tab>SOROLOGIA</v-tab> -->
        <v-tab>ANEXOS</v-tab>

        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-form @submit.prevent="editDoador" v-model="item" ref="form">
                <v-container>
                  <v-row>
                    <!-- <v-col class="d-flex" cols="12" sm="4"> área solicitou a remoção desse campo (05/02/2024)
                      <v-radio-group v-model="donorForm.kind_of_donor_id" disabled>
                        <p>Tipo de Doador:</p>

                        <v-radio label="PCR" 
                        value="1" 
                        color="#086318"
                        ></v-radio>

                        <v-radio label="ME " 
                        value="2" 
                        color="#086318"
                        ></v-radio>
                      </v-radio-group>
                    </v-col> -->

                    <v-col class="d-flex" cols="12" sm="4">
                      <v-radio-group disabled :rules="formRules" v-model="vue.identified">
                        <p>Doador Identificado?</p>

                        <v-radio label="Sim" 
                        value="true" 
                        color="#086318"
                        ></v-radio>

                        <v-radio label="Não" 
                        value="false" 
                        color="#086318"
                        ></v-radio>
                      </v-radio-group>

                    </v-col>

                    <v-col class="d-flex" cols="12" sm="4">
                      <v-radio-group 
                      :rules="formRules" 
                      v-model="donorForm.donor_status_id"
                      >

                      <p>Doador Vivo?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2 
                        color="#086318"></v-radio>
                      </v-radio-group>

                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="d-flex" cols="12" sm="4" v-if="this.$store.state.profile_id == 1">
                      <v-select 
                      :items="hospital"
                      :rules="formRules"
                      color="#086318" 
                      v-model="donorForm.hospital_id"
                      item-value="id"
                      item-text="hospital"  
                      dense 
                      outlined 
                      label="Hospital Notificante">
                      </v-select>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="2">
                      <v-text-field 
                      color="#086318" 
                      label="CNES" 
                      placeholder="CNES"
                      item-text="hospitalSelected.cnes"
                      outlined
                      dense
                      v-model="donorForm.cnes" 
                      maxlength="14" 
                      v-mask="'##############'"
                      ></v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="2">
                      <v-text-field
                      color="#086318" 
                      label="RGCT" 
                      placeholder="Apenas Números" 
                      outlined
                      maxlength="14" 
                      v-mask="'##############'"
                      v-model="donorForm.rgct" 
                      cpf  
                      dense></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <h3 row>Informações Pessoais</h3>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="4" v-if="vue.identified == 'true'">
                      <v-radio-group  
                      v-model="vue.hasCpf"
                      disabled
                      :rules="formRules">

                        <p>Possui CPF?</p>

                        <v-radio label="Sim"
                         value="true" 
                         color="#086318"
                         ></v-radio>

                        <v-radio label="Não"
                         value="false" 
                         color="#086318"
                         ></v-radio>
                      </v-radio-group>

                    </v-col>

                    <v-col class="d-flex" cols="12" sm="4">
                      <v-radio-group 
                      :rules="formRules" 
                      v-model="donorForm.sex_id">

                        <p>Gênero</p>

                        <v-radio 
                        label="Feminino" 
                        value=1 
                        color="#086318"
                        ></v-radio>

                        <v-radio 
                        label="Masculino" 
                        value=2 
                        color="#086318"
                        ></v-radio>

                        <v-radio 
                        label="Outros" 
                        value=3 
                        color="#086318"
                        ></v-radio>
                      </v-radio-group>

                    </v-col>

                    <v-col class="d-flex" cols="12" sm="4" v-if="vue.identified == 'true'">
                      <v-radio-group 
                      :rules="formRules" 
                      disabled
                      v-model="vue.minor">

                        <p>Menor de Idade?</p>

                        <v-radio 
                        label="Sim" 
                        value="true" 
                        color="#086318"
                        ></v-radio>

                        <v-radio 
                        label="Não" 
                        value="false" 
                        color="#086318"
                        ></v-radio>

                      </v-radio-group>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="d-flex" cols="12" sm="3" v-if="vue.hasCpf == 'true'">
                      <v-text-field 
                      color="#086318" 
                      :rules="formRules" 
                      label="CPF" 
                      placeholder="CPF" 
                      outlined
                      v-model="donorForm.cpf" 
                      v-mask="'###.###.###-##'" 
                      maxlength="14"
                      dense
                      ></v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="5">
                      <v-text-field 
                      color="#086318" 
                      :rules="formRules" 
                      label="Nome" 
                      placeholder="Nome completo" 
                      outlined
                      v-model="donorForm.name" 
                      dense></v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="3" v-if="vue.identified == 'true'">
                      <v-text-field 
                      color="#086318" 
                      :rules="formRules" 
                      label="Data de Nascimento"
          
                      type="date" 
                      outlined 
                      v-model="donorForm.birth_date"
                      dense></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="d-flex" cols="12" sm="4" v-if="vue.identified == 'true'">
                      <v-text-field 
                      color="#086318" 
                      :rules="formRules" 
                      label="Nome da Mãe" 
                      placeholder="Nome da mãe"
                      outlined 
                      v-model="donorForm.mother_name" 
                      dense
                      ></v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="2" v-if="vue.identified == 'true'">
                      <v-text-field 
                      color="#086318" 
                      :rules="formRules" 
                      label="Telefone" 
                      placeholder="Telefone" 
                      outlined
                      v-mask="'(##)#####-####'" 
                      maxlength="14"
                      v-model="donorForm.telephone" 
                      dense
                      ></v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="2" v-if="vue.identified == 'true'">
                      <v-text-field 
                      color="#086318" 
                      :rules="formRules" 
                      label="Cartão do SUS" 
                      placeholder="Cartão do SUS"
                      maxlength="15" 
                      v-mask="'#############'" 
                      outlined 
                      v-model="donorForm.sus_card" 
                      dense
                      ></v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="3" v-if="vue.identified == 'true'">
                        <v-select 
                        color="#086318" 
                        v-model="donorForm.country_id" 
                        :items="countries"
                        item-text="country_name" 
                        item-value="id" 
                        dense 
                        outlined
                        label="Nacionalidade">
                        </v-select>
                      </v-col>

                  </v-row>

                  <div class="address" v-if="vue.identified == 'true'">
                    <v-row>
                      <h3 class="titles" 
                      row>Endereço</h3>
                    </v-row>

                    <v-row>
                      <v-col class="d-flex" cols="12" sm="2">
                        <v-text-field 
                        color="#086318" 
                        label="CEP" 
                        @focusout="getCep"
                        v-mask="'########'"
                        placeholder=""
                        outlined
                        v-model="donorForm.zip_code" 
                        dense>
                        </v-text-field>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="3">
                        <v-select 
                        color="#086318" 
                        placeholder="uf" 
                        v-model="donorForm.state_id"
                        :disabled="disableCep"
                        :items="state"
                        item-text="name" 
                        item-value="id" 
                        dense 
                        outlined 
                        label="Estado">
                        </v-select>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="3">
                        <v-text-field 
                        color="#086318" 
                        items="county" 
                        v-model="donorForm.county"
                        item-text="name" 
                        item-value="id" 
                        :disabled="disableCep"
                        dense 
                        outlined 
                        label="Cidade">
                        </v-text-field>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="3">
                        <v-text-field 
                        color="#086318"  
                        label="Bairro" 
                        placeholder="Bairro"
                        :disabled="disableCep" 
                        outlined
                        v-model="donorForm.district" 
                        dense
                        ></v-text-field>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="1">
                        <v-text-field 
                        color="#086318" 
                        type="number"
                        label="N°" 
                        placeholder="" 
                        outlined
                        v-model="donorForm.number"
                         dense></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="6">
                        <v-text-field 
                        color="#086318" 
                        label="Endereço"
                        :disabled="disableCep"
                        placeholder="Ex: Rua X, Loteamento Y..." 
                        outlined 
                        v-model="donorForm.address"
                        dense
                        ></v-text-field>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="6">
                        <v-text-field 
                        color="#086318" 
                        :rules="formRules" 
                        label="Complemento"
                        placeholder="Ex: Próximo ao Mercadinho..." 
                        outlined 
                        v-model="donorForm.complement"
                        dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>

                  <div class="responsible" v-if="vue.minor == 'true'">
                    <v-row>
                      <h3 class="titles" row>Responsável</h3>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="7">
                        <v-text-field 
                        color="#086318" 
                        :rules="formRules" 
                        label="Nome do Responsável"
                        placeholder="Nome completo" 
                        outlined 
                        v-model="donorForm.responsible"
                        dense>
                      </v-text-field>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="5">
                        <v-select 
                          color="#086318"  
                          :items="kinships"
                          v-model="donorForm.kinship_id" 
                          item-text="name"
                          item-value="id" 
                          dense
                          outlined
                          label="Grau de Parentesco">
                        </v-select>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="buttons">
                    <v-row>
                        <div class="spacediferent"></div>
                        <v-btn class="rounded-lg" type="submit" elevation="0" color="green" @click="editDoador()">
                          <span class="textBtn"> Atualizar Doador </span>
                        </v-btn>
                        <div class="spacediferent"></div>
                        <v-btn class="rounded-lg" elevation="0" @click="$router.push('/doador')" color="grey">
                          <span class="textBtn"> Voltar</span>
                        </v-btn>
                    </v-row>
                </div>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>

          <v-snackbar absolute background-color="white" color="#104a21" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                Fechar
              </v-btn>
            </template>
        </v-snackbar>
        </v-tab-item>

        <v-tab-item>
            <EditarInformesClinicos />
        </v-tab-item>

        <!-- <v-tab-item>
            <EditarSorologia />
        </v-tab-item> -->

        <v-tab-item>
            <EditarAnexos />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>  
<script>

import axios from "@/axios";
import FormatDate from "@/utils/FormatDate"
import EditarInformesClinicos from "./informesClínicos/EditarInformesClinicos.vue";
//import EditarSorologia from "./sorologia/EditarSorologia-comp.vue";
import EditarAnexos from "./anexos/EditarAnexos-comp.vue";

export default {
    data() {
        return {
            item: "",
            donnoreditid: "",
            page: 1,
            headers: [
                {
                    text: "Orgão Doado",
                    value: "name",
                    align: "center"
                },
                {
                    text: "Apagar/Adicionar",
                    value: "actions",
                    align: "center"
                },
            ],
            disableCep: false,
            donor: [],
            label: false,
            newHospital: [],
            totalUsers: 0,
            numberOfPages: 0,
            passengers: [],
            loading: true,
            options: {},
            ativateOrgons: "false",
            sortBy: "name",
            sex_id: "",
            identified: "",
            donor_status_id: "",
            orgons: [],
            disabled: true,
            message: "",
            snackbar: false,
            loadingBTN: false,
            hospitalSelected: "",
            id: "",
            exibirMensagem: false,
            messageError: "",
            donorForm: {},
            orgonsTable: [],
            kinships: [],
            hospital: [],
            countries: [],
            hospitalRegistred: [],
            disabledReports: false,
            state: [],
            vue: {
                identified: null,
                hasCpf: null,
                minor: null,
                donor_status_id: null
            },
            formRules: [
                (v) => !!v || "Este campo é obrigatório",
            ],
        };
    },
    prop: {
        resource: {
            type: Object,
        },
    },
    created() {

      if(this.donorForm.rg != null){ this.disabledReports == true}
        // //chegada de items
        this.$route.query.item.hospital_id = parseInt(this.$route.query.item.hospital_id);
        this.$route.query.item.country_id = parseInt(this.$route.query.item.country_id);
        this.$route.query.item.state_id = parseInt(this.$route.query.item.state_id);
        this.$route.query.item.kinship_id = parseInt(this.$route.query.item.kinship_id);
        this.$route.query.item.birth_date = FormatDate.dataAtualFormatada(this.$route.query.item.birth_date);
        
        this.donorForm = { ...this.$route.query.item };
        let cnes = this.donorForm.unities.cnes
        this.donorForm.cnes = cnes
        localStorage.setItem("donorId", this.donorForm.id);
        this.orgons = [...this.$route.query.item.orgon];

        //disabled cpf
        if (this.donorForm.cpf) {
            this.vue.hasCpf = "true";
        }
        else {
            this.vue.hasCpf = "false";
        }
        //disabled minor
        if (this.donorForm.responsible) {
            this.vue.minor = "true";
        }
        else {
            this.vue.minor = "false";
        }
        //disabled identified
        if (this.donorForm.complement) {
            this.vue.identified = "true";
        }
        else {
            this.vue.identified = "false";
        }

        //reconhece se veio de busca ativa
        if(this.donorForm.rg != null){
            this.vue.identified = "true"
        }
        
        axios.get("/unities").then((response) => {
            this.hospital = response.data.data;
        });
        axios.get("/kinship").then((response) => {
            this.kinships = response.data.data;
        });
        axios.get("/state").then((response) => {
            this.state = response.data.data;
        });
        axios.get("/countries").then((response) => {
            this.countries = response.data.data;
        });
        axios.get("/orgon").then((response) => {
            this.orgonsTable = response.data.data;
        });
    },
    
    computed: {
        hospital_id() {
            return this.donorForm.hospital_id;
        }
    },

    watch: {
        sortBy: {
            handler() {
            }
        },
        options: {
            handler() {
                this.indoDataTable();
            },
        },

        hospital_id(e) {
            var hospitalSelected = this.hospital.filter((x) => x.id == e)[0];
            this.donorForm.cnes = hospitalSelected.CNES;
        },
        deep: true,
    },
    
    methods: {
        indoDataTable() {
            this.loading = true;
        },
        openCreateDoador() {
            this.dialog = true;
        },
        addOrgon() {
            this.ativateOrgons = true;
        },

        getCep(){
          var cep = this.donorForm.zip_code
          axios.get(`/donor/cep/${cep}`).then((response) => {
            if(response.data.erro == true){
              alert("CEP não encontrado.")
              this.donorForm.zip_code = cep
              this.donorForm.district = ""
              this.donorForm.county = ""
              this.donorForm.address = ""
            }else{
              var uf = response.data.uf
                axios.get("/state").then((response) => {
                    this.state = response.data.data;
                    this.state = this.state.filter((x) => x.uf == uf)[0];
                    this.donorForm.state_id = this.state.id
                });
                this.donorForm.zip_code = response.data.cep
                this.donorForm.district = response.data.bairro
                this.donorForm.county = response.data.localidade
                this.donorForm.address = response.data.logradouro
            }
          }).catch((err) => {
            err;
            var error = "";
            alert("Preencha um CEP válido", error)
          });
        },

        editDoador() {
            if (this.$refs.form.validate()) {
                const novoDoador = (this.donorForm);
                axios
                    .patch(`/donor/${novoDoador.id}`, novoDoador)
                    .then(() => {
                    this.indoDataTable();
                    this.message = "Doador editado!";
                    this.snackbar = true;
                    this.dialog = false;
                    this.$router.push({ name: "Doadores" });
                })
                    .catch((err) => {
                    err;
                    var error = "";
                    Object.values(this.$store.state.errorMessage).map((value) => {
                        error = value;
                    });
                    this.displayError.message = error.toString();
                    this.displayError.display = true;
                    setTimeout(() => {
                        this.displayError.display = false;
                    }, 3000);
                });
            }
        },
        mounted() {
            this.indoDataTable();
        },
    },
    components: { EditarInformesClinicos,
                  // EditarSorologia,
                  EditarAnexos }
};
</script>

<style scoped>
.header-table {
  display: flex;
  margin-bottom: 20px;
}

.space {
  width: 15px;
}

.spacediferent {
  width: 7px;
}

.space-personalized {
  padding: 15px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right,
      #27ae60,
      #1abc9c);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,
      #ee106d,
      #e46987);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.icon {}

.banner>h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>
