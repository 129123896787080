<template>
    <div>
        <v-card-text>
            <v-form @submit.prevent="createAttachments" ref="form">
                <v-container>
                    <br>
                    <v-row>
                        <v-col class="d-flex" cols="12" sm="4">
                            <v-text-field
                            color="#086318" 
                            label="Nome do Exame" 
                            :rules="formRules" 
                            outlined
                            v-model="anexosForm.attachment_name" 
                            dense></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="5">
                            <v-file-input
                        v-model="anexosForm.attachment"
                        label="Documento em PDF"
                        class="borderRadius10"
                        color="black"
                        append-icon="mdi-file"
                        prepend-icon=""
                        show-size
                        outlined
                        dense>
                        </v-file-input>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="3">
                            <v-tooltip right color="green">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="mx-1" small fab dark color="green" v-bind="attrs" v-on="on" @click="addAttachment()">
                                        <v-icon dark> mdi-plus </v-icon>
                                    </v-btn>
                                </template>
                            <span>Inserir documento</span>
                            </v-tooltip>
                            <p class="space"></p>
                        </v-col>
                    </v-row>

                    <template>
                        <v-data-table
                            :headers="headers"
                            :items="arquivos"
                            :items-per-page="5"
                            class="elevation-1">
                            <template v-slot:item.actions="{ item }">
                                <v-icon 
                                size="20"
                                class="icon"
                                color="red" 
                                align-item="center"
                                @click="deleteAttachment(item)">
                                mdi-trash-can-outline
                                </v-icon>
                            </template>
                        </v-data-table>
                    </template>

                    <p class="space-personalized"></p>
                
                    <!-- MODAL DE CONFIRMAÇÃO -->
                    <v-dialog persistent width="auto" v-model="dialogDonor">
                        <v-card>
                            <v-card-title class="text-h5">
                            Doador
                            </v-card-title>
                            <v-card-text>Paciente {{ donorName }} pode ser considerado doador(a)?</v-card-text>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="#ed695a"
                                    variant="text"
                                    value="Não"
                                    @click="dialogDonor = false, openModal = true"
                                >
                                    Não
                                </v-btn>
                                <v-btn
                                    color="#8fdb8f"
                                    variant="text"
                                    value="Sim"
                                    @click="saveDonor()" 
                                >
                                    Sim
                                </v-btn>
                                </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <div class="buttons">
                        <v-row>
                            <v-btn class="rounded-lg" elevation="0" color="#166e25" v-if="this.donorButton" @click="dialogDonor = true">
                                <span class="textBtn">Salvar Doador</span>
                            </v-btn>
                            <p class="space-personalized"></p>
                            <v-btn class="rounded-lg" elevation="0" color="green" @click="$router.push('/doador')">
                                <span class="textBtn">Sair</span>
                            </v-btn>
                        </v-row>
                    </div>

                    <AceiteFamiliaDoador :activeModal="openModal" :donorId="anexosForm" @fechar="openModal = false"/>
                </v-container>
            </v-form>
        </v-card-text>
        <v-snackbar absolute background-color="white" color="green" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
  
<script>
import axios from "@/axios";
import AceiteFamiliaDoador from "../../../modals/AceiteFamiliaDoador.vue";

export default {
    data() {
        return {
            tab: 2,
            dialog: false,
            displayError: {
                display: false,
                message: [],
            },
            anexosForm: {
                donor_id: this.$route.query.item.id,
                attachments_type_id: 1
            },
            headers: [
                {
                    text: "Nome do exame",
                    align: "center",
                    value: "attachment_name",
                },
                {
                    text: "Ações",
                    value: "actions",
                    align: "center",
                    sortable: false
                },
            ],
            dialogDonor: false,
            openModal: false,
            donor_id: "",
            loading: true,
            options: {},
            donor: [],
            data: [],
            arquivos: [],
            status_serology: [],
            selected: "",
            message: "",
            snackbar: false,
            messageError: "",
            unity: "",
            donorID: "",
            donorName: "",
            donorButton: true,
            formRules: [
                (v) => !!v || "Este campo é obrigatório",
            ],
        };
    },
    created() {
        this.donorID = this.$route.query.item.id;
        if(this.$route.query.item.authorized != null){
            this.donorButton = false
        }

        axios.get("/attachments").then((response) => {
            this.arquivos = response.data.data;
            this.arquivos = this.arquivos.filter((x) => x.donor_id == this.donorID);
        });

    },

    watch: {
        options: {
            handler() {
                this.indoDataTable();
            },
        },
        deep: true,
    },
    methods: {
        indoDataTable() {
            this.loading = true;
        },

        addAttachment() {
            if (this.$refs.form.validate()) {
                this.anexosForm.donor_id = this.donorID;
                let anexosData = new FormData();
                anexosData.append("donor_id", this.anexosForm.donor_id);
                anexosData.append("attachment_name", this.anexosForm.attachment_name);
                anexosData.append("attachment", this.anexosForm.attachment);
                anexosData.append("attachments_type_id", this.anexosForm.attachments_type_id);
                axios
                    .post(`/attachments/create`, anexosData)
                    .then((response) => {
                    this.indoDataTable();
                    this.anexosForm.attachment_name = "";
                    this.anexosForm.attachment = null;
                    this.message = response.data.message;
                    this.snackbar = true;
                    this.dialog = false;
                })
                    .catch((err) => {
                    err;
                    var error = "";
                    Object.values(this.$store.state.errorMessage).map((value) => {
                        error = value;
                    });
                    this.displayError.message = error.toString();
                    this.displayError.display = true;
                    setTimeout(() => {
                        this.displayError.display = false;
                    }, 3000);
                }).finally(() => {
                    this.loadTable();
                });
            }
        },
        loadTable() {
            axios.get("/attachments").then((response) => {
                this.arquivos = response.data.data;
                this.arquivos = this.arquivos.filter((x) => x.donor_id == this.anexosForm.donor_id);
            });
        },
        deleteAttachment(item) {
            const attachmentId = item.id;
            axios.delete(`/attachments/${attachmentId}`).then((response) => {
                this.loading = true;
                this.message = response.data.message;
                this.snackbar = true;
                this.loadTable();
            });
        },

        saveDonor(){
            const data =  new Object();
            data.authorized = 2

            axios
                .patch(`/donor/${this.donorID}`, data)
                .then(() => {
                this.message = "Paciente agora é um doador!";
                this.snackbar = true;
                this.dialog = false;
                this.$router.push({ name: "Doadores" });
            })
                .catch((err) => {
                err;
                var error = "";
                Object.values(this.$store.state.errorMessage).map((value) => {
                    error = value;
                });
                this.displayError.message = error.toString();
                this.displayError.display = true;
                setTimeout(() => {
                    this.displayError.display = false;
                }, 3000);
            });
        },

        createAttachments() {
            if (this.$refs.form.validate()) {
                this.anexosForm.donor_id = this.donorID;
                const attachments = (this.anexosForm);
                axios
                    .post(`/attachments`, attachments)
                    .then((response) => {
                    this.indoDataTable();
                    this.message = response.data.message;
                    this.snackbar = true;
                    this.dialog = false;
                })
                    .catch((err) => {
                    err;
                    var error = "";
                    Object.values(this.$store.state.errorMessage).map((value) => {
                        error = value;
                    });
                    this.displayError.message = error.toString();
                    this.displayError.display = true;
                    setTimeout(() => {
                        this.displayError.display = false;
                    }, 3000);
                });
            }
        },
    },
    mounted() {
        this.indoDataTable();
    },
    components: { AceiteFamiliaDoador }
};
</script>
  
<style scoped>
.header-table {
    display: flex;
    margin-bottom: 20px;
}

p {
    font-size: 16px;
    /* font-weight: bold; */
}

.d-flex {
    padding: 0 12px;
}

.space {
    width: 5px;
}

.space-personalized {
    padding: 4px;
}

.spacediferent {
    width: 7px;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
}

.textBtn {
    font-family: "Poppins";
    color: #ffffff;
}

.create-user-dialog {
    padding: 20px;
}

.card {
    padding: 35px;
}

.banner {
    background: #b3ffab;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right,
            #27ae60,
            #1abc9c);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,
            #ee106d,
            #e46987);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-radius: 8px;

    margin-bottom: 20px;

    display: flex;
    align-items: center;
}

.icon {}

.banner>h4 {
    color: #ffffff;
    padding: 10px;
}

.cardFooter {
    padding: 0 !important;
}
</style>
  